import camelCase from 'lodash/camelCase'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import snakeCase from 'lodash/snakeCase'
import URI from 'urijs'
import { metaTags } from 'utils'

export * as Routes from 'routes/routes'

class HttpError extends Error {
  constructor(message, status, url, json) {
    super(message)
    this.name = 'HttpError'
    this.status = status
    this.url = url
    if (json) this.responseJSON = json
    if (Error.captureStackTrace) Error.captureStackTrace(this, HttpError)
  }
}

const filters = []
const jsonHeaders = {
  Accept: 'application/json, text/javascript, */*',
  'Content-Type': 'application/json',
  'X-Csrf-Token': metaTags.csrfToken(),
}

function urlFromRoute(route) {
  const parts = URI.parse(route)
  parts.path = parts.path.endsWith('.json') ? parts.path : parts.path + '.json'
  return URI.build(parts)
}

function sanitizeData(data) {
  if (!data) return data
  const entries = Object.entries(data).filter(([_, v]) => v !== undefined && (!Array.isArray(v) || v.length))
  return Object.fromEntries(entries)
}

function objectToParams(obj, key = '') {
  // Recursion passes down sub-object notation to generate correct URI key names
  return Object.entries(obj).reduce((collector, [k, v]) => keyValuePairs(collector, key ? `${key}[${k}]` : k, v), [])
}

function arrayToParams(array, key) {
  // Recursion passes down key-array notation to generate nested array URI key names
  const newKey = key + '[]'
  return array.reduce((collector, elem) => keyValuePairs(collector, newKey, elem), [])
}

function keyValuePairs(collector, key, value) {
  // This recursive function flattens nested objects and arrays into key-value pairs
  if (Array.isArray(value)) collector.push(...arrayToParams(value, key))
  else if (typeof value === 'object') collector.push(...objectToParams(value, key))
  else collector.push([key, value])
  return collector
}

function objectToQueryString(data) {
  return data ? new URLSearchParams(objectToParams(data)).toString() : ''
}

function toJson(result) {
  return result.status === 204 ? '' : result.json()
}

export const HTTP = {
  getJSON(route, data) {
    const opts = { headers: jsonHeaders, query: sanitizeData(data) }
    return HTTP.fetch(urlFromRoute(route), opts).then(toJson)
  },

  getJSONEx(route, data) {
    const opts = { headers: jsonHeaders, query: sanitizeData(data) }
    return HTTP.fetch(urlFromRoute(route), opts)
  },

  create(url, data = {}) {
    const opts = { method: 'POST', headers: jsonHeaders, body: JSON.stringify(sanitizeData(data)) }
    return HTTP.fetch(urlFromRoute(url), opts).then(toJson)
  },

  put(url, data) {
    const opts = { method: 'PUT', headers: jsonHeaders, body: JSON.stringify(sanitizeData(data)) }
    return HTTP.fetch(urlFromRoute(url), opts).then(toJson)
  },

  patch(url, data) {
    const opts = { method: 'PATCH', headers: jsonHeaders, body: JSON.stringify(sanitizeData(data)) }
    return HTTP.fetch(urlFromRoute(url), opts).then(toJson)
  },

  delete(url) {
    const opts = { method: 'DELETE', headers: jsonHeaders }
    return HTTP.fetch(urlFromRoute(url), opts).then(toJson)
  },

  ajax(url, options) {
    return HTTP.fetch(urlFromRoute(url), options).then(toJson)
  },

  fetch(url, options = {}) {
    const { query = {}, ...fetchOptions } = options
    const queryStr = objectToQueryString(query)
    const urlWithSearch = queryStr ? `${url}?${queryStr}` : url
    for (let i = 0; i < filters.length; ++i) {
      if (filters[i](urlWithSearch, fetchOptions)) return Promise.resolve(new Response('{}'))
    }
    return fetch(urlWithSearch, fetchOptions).then(async (result) => {
      if (!result.ok) {
        const json = await result.json()
        throw new HttpError('HTTP.fetch failure', result.status, urlWithSearch, json)
      }
      return result
    })
  },

  addFilter(filter) {
    filters.push(filter)
  },

  removeFilter(filter) {
    const index = filters.indexOf(filter)
    if (index >= 0) filters.splice(index, 1)
  },

  clearFilters() {
    filters.splice(0)
  },
}

export function camelCaseKeys(obj) {
  return caseKeys(obj, camelCase)
}

export function snakeKeys(obj) {
  return caseKeys(obj, snakeCase)
}

function caseKeys(obj, casing) {
  if (isPlainObject(obj)) {
    let mappedValue

    return mapValues(applyCaseToKeys(obj, casing), (value) => {
      if (isPlainObject(value)) {
        mappedValue = caseKeys(value, casing)
      } else if (isArray(value)) {
        mappedValue = map(value, (item) => caseKeys(item, casing))
      } else {
        mappedValue = value
      }

      return mappedValue
    })
  } else if (isArray(obj)) {
    return map(obj, (item) => caseKeys(item, casing))
  } else {
    return obj
  }
}

function applyCaseToKeys(obj, applyCase) {
  return mapKeys(obj, (value, key) => {
    if (key === '_destroy') return key
    return applyCase(key)
  })
}

export function getFormDataObject(form) {
  const formData = new FormData(form)
  const formDataObject = {}

  for (const entry of formData.entries()) {
    const [key, value] = entry
    const namespacedKeys = key.split('[')

    let currentObject = formDataObject
    let lastKey = ''
    let lastObject = null
    for (let i = 0; i < namespacedKeys.length; i++) {
      const namespacedKey = namespacedKeys[i].replace(']', '')
      if (namespacedKey === '') {
        if (Array.isArray(currentObject)) {
          if (i === namespacedKeys.length - 1) {
            currentObject.push(value)
          } else {
            const lastItemInArray = currentObject.at(-1)
            lastObject = currentObject
            lastKey = namespacedKey

            const nextKey = namespacedKeys[i + 1].replace(']', '')
            if (lastItemInArray && lastItemInArray[nextKey] === undefined) {
              currentObject = lastItemInArray
            } else {
              currentObject = {}
              lastObject.push(currentObject)
            }
          }
        } else {
          if (i === namespacedKeys.length - 1) {
            lastObject[lastKey] = [value]
          } else {
            currentObject = {}
            lastObject[lastKey] = [currentObject]
          }
        }
      } else if (i === namespacedKeys.length - 1) {
        currentObject[namespacedKey] = value
      } else {
        if (!currentObject[namespacedKey]) {
          currentObject[namespacedKey] = {}
        }
        lastKey = namespacedKey
        lastObject = currentObject
        currentObject = currentObject[namespacedKey]
      }
    }
  }

  return formDataObject
}
