import assign from 'lodash/assign'
import compact from 'lodash/compact'
import get from 'lodash/get'
import each from 'lodash/each'
import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import set from 'lodash/set'

import $script from 'scriptjs'
import debug from 'debug'
const log = debug('app:clients/learnosity')

import { apiVersion, isEnabled } from './learnosity/config'
import { captureException } from './sentry'

log('api version', apiVersion)
const learnosityItemsUrl = `https://items.learnosity.com/?${apiVersion}`
const learnosityReportsUrl = `https://reports.learnosity.com/?${apiVersion}`
const learnosityAuthorUrl = `https://authorapi.learnosity.com/?${apiVersion}`
const learnosityQuestionsUrl = `https://questions.learnosity.com/?${apiVersion}`

function getOrSet(object, path, callback) {
  let value = get(object, path)
  value = value ? value : callback()
  set(object, path, value)
  return value
}

const itemsAppRegistry = {}
const itemsAppStub = {
  getItems() {
    return {}
  },
  questions() {
    return {}
  },
}

export function fetchItemsApp(config) {
  if (!isEnabled()) {
    log('not connected to the Learnosity Items API')
    return Promise.resolve(itemsAppStub)
  }
  const {
    request: { session_id: sessionId },
  } = config
  if (!sessionId) throw new Error('LearnosityItem.init requires a session_id')
  return getOrSet(itemsAppRegistry, sessionId, () => newItemsApp(config))
}

function newItemsApp(config) {
  const {
    request: { session_id: sessionId },
  } = config
  $script(learnosityItemsUrl, 'items.learnosity')

  return new Promise((resolve, reject) => {
    log('LearnosityItem.init begin', sessionId)

    $script.ready('items.learnosity', () => {
      const app = window.LearnosityItems.init(config, {
        customUnload() {
          return !app.suppressReloadSiteConfirmation && !(typeof app.safeToUnload === 'function' && app.safeToUnload())
        },
        readyListener() {
          log('LearnosityItems.init resolve', sessionId, app)
          resolve(app)
        },
        errorListener(err) {
          captureLearnosityError(err, { during: 'LearnosityItems.init reject', sessionId: sessionId })
          reject(err)
        },
      })
    })
  })
}

export function reset() {
  Object.keys(itemsAppRegistry).forEach((key) => delete itemsAppRegistry[key])
}

export function newReportsApp(config) {
  $script(learnosityReportsUrl, 'reports.learnosity')

  return new Promise((resolve, reject) => {
    $script.ready('reports.learnosity', () => {
      const app = window.LearnosityReports.init(config, {
        readyListener: () => resolve(app),
        errorListener: (err) => {
          captureLearnosityError(err, { during: 'LearnosityReports.init reject' })
          reject(err)
        },
      })
    })
  })
}

// FIXME refactor to use native promises
// also consider supporting an observable passed in
export function newQuestionsApp(config) {
  $script([learnosityQuestionsUrl, learnosityItemsUrl], 'questions.learnosity')

  return new Promise((resolve, reject) => {
    $script.ready('questions.learnosity', () => {
      const app = window.LearnosityApp.init(config, {
        readyListener: () => resolve(app),
        errorListener(err) {
          captureLearnosityError(err, { during: 'LearnosityApp.init reject' })
          reject(err)
        },
      })
    })
  })
}

// use LearnosityAuthorClient instead of using this directly
export function newAuthorApp(config) {
  $script(learnosityAuthorUrl, 'author.learnosity')
  return new Promise((resolve, reject) => {
    $script.ready('author.learnosity', () => {
      const app = window.LearnosityAuthor.init(config, {
        readyListener: () => resolve(app),
        errorListener: (err) => reject(err),
      })
    })
  })
}

export function captureLearnosityError(errorOrDetails = {}, context = {}) {
  log(context, errorOrDetails)
  const extra = assign({}, context)

  const globalErrors = compact(
    flatMap(globalNamespaces, (global) => {
      return map(get(window, `${global}.errors`), (error, index) => {
        return assign({ global, index }, error)
      })
    }),
  )

  each(globalErrors, (error) => {
    extra[`${error.global}.errors[${error.index}]`] = compact([error.code, error.msg]).join(': ')
  })

  if (errorOrDetails instanceof Error) {
    return captureException(errorOrDetails, { extra })
  }

  const {
    code = get(globalErrors[0], 'code'),
    msg = get(globalErrors[0], 'msg') || 'Unspecified Learnosity error',
    ...rest
  } = errorOrDetails

  return captureException(new Error(compact([code, msg]).join(': ')), { extra: assign(extra, rest) })
}

export const globalNamespaces = ['LearnosityApp', 'LearnosityAuthor', 'LearnosityItems', 'LearnosityReports']
