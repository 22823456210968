import { fetchItemsApp } from 'clients/learnosity'
import { rerenderMathJax } from 'clients/mathjax'
import pager from './printer/pager'
import watchLearnosityLoading from './printer/watch_learnosity_loading'
import platform from 'platform'
import addSafariStyling from './printer/add_safari_styling'
import { captureException } from 'clients/sentry'

export async function onReadyPrint(lrnoConfig) {
  if (platform.name === 'Safari') {
    addSafariStyling()
  }

  if (lrnoConfig) {
    const learnosityLoadingPromises = watchLearnosityLoading()
    try {
      await fetchItemsApp(lrnoConfig)
    } catch (e) {
      captureException(e)
    }
    await Promise.all(learnosityLoadingPromises)
  }

  const mathEls = document.querySelectorAll('.math-tex')
  mathEls.forEach((mathEl) => {
    rerenderMathJax(mathEl)
  })

  window.MathJax.Hub.Queue(async () => {
    await pager()
    document.querySelector('.loading').remove()
    document.querySelector('#un-formatted').remove()
    document.querySelectorAll('.page-content').forEach((e) => e.classList.add('overflow-hidden'))
    window.setTimeout(window.print, 400)
  })
}
